



































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { FormHelper } from '@/mixins'
import { Game } from '@/api'

@Component
export default class RbbRanking extends Mixins(FormHelper) {
  @Prop() data
  currentSeasonSelected = {} as any
  currentSeasonDataSelected = [] as any
  currentClassSelected = {} as any
  classes = [
    { title: 'Select a class', value: null },
    { title: 'Swordsman', value: 'Swordman', },
    { title: 'Archer', value: 'Archer', },
    { title: 'Magician', value: 'Magician', }
  ] as any
  seasons = [] as any
  fields = [
    { key: 'rank' },
    { key: 'class' },
    { key: 'character', label: 'Character Name' },
    { key: 'level', label: 'Character Level' },
    { key: 'winrate', label: 'Wins / Loses' },
    { key: 'kda', label: 'Kill / Deaths'},
    { key: 'stats.totalPoints', label: 'Total Points', sortable: true }
  ]
  
  @Watch('currentClassSelected')
  onCurrentClassSelectedChange(newClassSelected) {
    if (!newClassSelected)
      this.currentClassSelected = {
        title: 'Select a class',
        value: null
      }
  }

  @Watch('currentSeasonSelected')
  async onCurrentSeasonSelectedChange(newSeasonSelected) {
    if (this.data.seasonId === newSeasonSelected.value) {
      this.currentSeasonDataSelected = []
      return
    }

    const test = await Game.getRbbSeasonRecords(newSeasonSelected.value)

    this.currentSeasonDataSelected = test
  }

  get parsedData() {
    const stats = this.currentSeasonDataSelected?.stats?.length ? this.currentSeasonDataSelected.stats : this.data.stats

    return this.currentClassSelected?.value
      ? stats.filter(e => e.stats.classType === this.currentClassSelected.value)
      : stats
  }

  async mounted() {
    this.currentSeasonSelected = {
      title: `Season ${this.data.seasonId}`,
      value: this.data.seasonId,
    }

    this.currentClassSelected = {
      title: 'Select a class',
      value: null
    }

    this.seasons = Array
      .from({length: this.data.seasonId }, (_, index) => index + 1)
      .map(e => ({
        title: `Season ${e}`,
        value: e,
      }))
  }

  displayKD(stats) {
    const { totalKills, totalDeaths } = stats

    return `${totalKills} / ${totalDeaths}`
  }

  displayWinrate(stats) {
    const { totalWins, totalLoses } = stats
    const winrate = totalWins / (totalWins + totalLoses)

    return `${totalWins}W ${totalLoses}L (${Math.round(winrate * 1000) / 10}%)`
  }
}
